import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import { Dialog, Icon, Toast } from "vant";
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Toast);

import "./assets/basis.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
