import OSS from "ali-oss";
import UUID from "../util/uuid";
import FILE from "../util/file";
import AppConfig from "../config";
import Ocean from "./ocean";

const BucketArea = {
  Image: "img",
  ImageReport: "imgrpt",
  Rtf: "rtf",
  Doc: "doc",
};

const _img_version = "v1";
const _imgrpt_version = "v1";
const _rtf_version = "v1";
const _doc_version = "v1";

var client = null;
var stsToken = null;

/**
 *
 * @param {*} uid
 * @returns
 */
async function getClient(uid) {
  let now = new Date();
  if (client && now < stsToken.expirationDate) return client;

  client = null;
  stsToken = null;

  await Ocean.getAliOssStsAssumerole(uid)
    .then((res) => {
      stsToken = res.data.credentials;
      stsToken.expirationDate = new Date(stsToken.expiration);
    })
    .catch((/* err */) => {
      throw "OSS授权失败!";
    });

  client = new OSS({
    // 目前，ocean获取STS token时使用region方式
    // endpoint: AppConfig.aliossEndpointCdn,
    // cname: true,
    region: AppConfig.aliossRegion,
    bucket: AppConfig.aliossBucket,
    accessKeyId: stsToken.accessKeyId,
    accessKeySecret: stsToken.accessKeySecret,
    stsToken: stsToken.securityToken,
    secure: true,
  });
  return client;
}

/**
 * @param
 */
function getEndpoint() {
  return AppConfig.aliossEndpoint;
}

function getEndpointCdn() {
  return AppConfig.aliossEndpointCdn;
}

/**
 * @param objectName 对象名，格式：{guid}.{扩展名}。
 * @param id
 */
function getKey(objectName, id) {
  if (id) {
    return id + "/" + objectName;
  } else {
    return objectName;
  }
}

/**
 * 获取图片OSS object name
 * @param {*} filename 图片文件名（或者路径）
 */
function getImageName(filename) {
  if (filename) {
    return _img_version + UUID.guid() + "." + FILE.getFileExtension(filename);
  }
}

/**
 * @param objectName 对象名，格式：{guid}.{扩展名}。
 * @param id
 */
function getImageKey(objectName, id) {
  if (id) {
    return BucketArea.Image + "/" + id + "/" + objectName;
  } else {
    return BucketArea.Image + "/" + objectName;
  }
}

/**
 * 获取形象报告OSS object name
 * @param {*} doctype 文档类型
 */
function getImageReportName(doctype) {
  let name = _imgrpt_version + UUID.guid();
  if (doctype) {
    name += "." + doctype;
  }
  return name;
}

/**
 * @param objectName 对象名，格式：{guid}.{扩展名}。
 * @param id
 */
function getImageReportKey(objectName, id) {
  if (id) {
    return BucketArea.ImageReport + "/" + id + "/" + objectName;
  } else {
    return BucketArea.ImageReport + "/" + objectName;
  }
}

/**
 * 获取富文本的OSS key
 */
function getRtfKey(id) {
  let name = _rtf_version + UUID.guid() + ".rtf";
  let key;
  if (id) {
    key = BucketArea.Rtf + "/" + id + "/" + name;
  } else {
    key = BucketArea.Rtf + "/" + name;
  }
  return key;
}

/**
 * 获取文档OSS object name
 * @param {*} doctype 文档类型
 */
function getDocName(doctype) {
  let name = _doc_version + UUID.guid();
  if (doctype) {
    name += "." + doctype;
  }
  return name;
}

/**
 * @param objName 对象名，格式：{guid}.{扩展名}。
 * @param id
 */
function getDocKey(objName, id) {
  if (id) {
    return BucketArea.Doc + "/" + id + "/" + objName;
  } else {
    return BucketArea.Doc + "/" + objName;
  }
}

/**
 * @param objectKey OSS对象key，包含bucket area 。
 */
function getUrl(objectKey) {
  return AppConfig.aliossEndpoint + "/" + objectKey;
}

function getCdnUrl(objectKey) {
  return AppConfig.aliossEndpointCdn + "/" + objectKey;
}

/**
 *
 * @param {*} key
 * @param {*} width
 * @param {*} height
 */
function getImageThumbnailCdnUrl(key, width = 320, height = 320) {
  let url = getCdnUrl(key);
  url += "?x-oss-process=image/resize,m_lfit";
  if (width) {
    url += ",w_" + width;
  }
  if (height) {
    url += ",h_" + height;
  }
  return url;
}

export default {
  OSS: OSS,
  getClient,
  getEndpoint,
  getEndpointCdn,
  getKey,
  getImageName,
  getImageKey,
  getImageReportName,
  getImageReportKey,
  getRtfKey,
  getDocName,
  getDocKey,
  getUrl,
  getCdnUrl,
  getImageThumbnailCdnUrl,
};
