<template>
  <div ref="ttContainer" class="tt-container">
    <div ref="ttToolbarContainer" class="tt-toolbar-container">
      <div ref="ttToolbar" class="tt-toolbar">
        <span
          role="button"
          class="tt-complete tt-tool"
          @click="onCompleteClick"
        >
          <span ref="ttCompleteIcon" class="tt-icon"></span>
        </span>
        <!--
        <span
          role="button"
          class="tt-keyboard tt-tool"
          @click="onKeyboardClick"
        >
          <span ref="ttKeyboardIcon" class="tt-icon"></span>
        </span>
        -->
        <div class="tt-separator"></div>
        <div class="tt-toolbar-tools-wrapper">
          <span class="tt-toolbar-tools"></span>
        </div>
      </div>
    </div>

    <slot name="header"></slot>
    <editor-content ref="ttContent" class="tt-content" :editor="editor" />
    <slot name="footer"></slot>
  </div>
</template>

<script>
import extend from "extend";

import { Editor, EditorContent } from "@tiptap/vue-2";

import { create as createEditor } from "@weiruo/tiptap-editor/src/editor";
import "@weiruo/tiptap-editor/src/editor.css";

import { ToolMenu } from "./toolMenu";
import "./toolMenu.css";

import Icons from "../../assets/icons";

export default {
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      defOptions: {
        toolMenu: {
          toolbar: ".tt-toolbar-tools",
        },
      },
      toolMenu: null,
      /* 内容发生改变 */
      changed: false,
    };
  },
  props: {
    content: String,
    value: String,
    /**
     * {
     *   toolMenu: {
     *     items, // [Array, undefined]
     *     image: {
     *       upload(): Function
     *     },
     *     shouldShow: true, // 始终显示toolMenu，包括编辑器不能编辑时。
     *   },
     *   onSave: (this) => {},
     * }
     */
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    content(newVal /* oldVal */) {
      if (!this.editor) return;

      const isSame = this.editor.getHTML() === newVal;
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(newVal);
      if (isSame) return;

      this.editor.commands.setContent(newVal, false);
    },
    value(newVal /* oldVal */) {
      if (!this.editor) return;

      const isSame = this.editor.getHTML() === newVal;
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(newVal);
      if (isSame) return;

      this.editor.commands.setContent(newVal, false);
    },
    editable(newVal, oldVal) {
      if (this.editor && newVal != oldVal) {
        if (newVal) {
          this.openEditable();
        } else {
          this.closeEditable();
        }
      }
    },
  },
  beforeCreate() {},
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    initialize() {
      const editorOptions = extend(true, {}, this.defOptions, this.options);

      this.toolMenuOptions = editorOptions.toolMenu;
      delete editorOptions.toolMenu;

      // 防止：添加初始内容后，将初始内容作为第一个编辑历史，执行undo将编辑器内容变为空白。
      if (this.value || this.content) {
        editorOptions.content = this.value || this.content;
      }

      this.editor = createEditor(Editor, editorOptions);

      if (this.editable) {
        this.openEditable();
      } else {
        this.editor.setEditable(false);
      }
    },
    initToolMenu(options) {
      // this.$refs.ttKeyboardIcon.innerHTML = Icons.keyboard;
      this.$refs.ttCompleteIcon.innerHTML = Icons.complete;
      this.toolMenu = new ToolMenu(this, options || {});
    },
    onUpdate(/* { editor } */) {
      if (!this.changed) {
        this.changed = true;
      }

      // HTML: editor.getHTML()
      // JSON: editor.getJSON()
      this.$emit("input", this);
    },
    onSelectionUpdate(/* { editor } */) {
      this.toolMenu.update();
    },
    openEditable() {
      if (!this.editor.isEditable) {
        this.editor.setEditable(true);
      }
      this.editor.on("update", this.onUpdate);
      this.editor.on("selectionUpdate", this.onSelectionUpdate);
      document.body.addEventListener("click", this.handleBodyClick);
      // document.body.addEventListener('dblclick', this.handleBodyClick);
      // this.editor.on('focus', this.onFocus);
      // this.editor.on('blur', this.onBlur);

      if (!this.toolMenu) {
        this.initToolMenu(this.toolMenuOptions);
      }
    },
    closeEditable() {
      if (this.editor.isEditable) {
        this.editor.setEditable(false);
      }
      this.editor.off("update", this.onUpdate);
      this.editor.off("selectionUpdate", this.onSelectionUpdate);
      document.body.removeEventListener("click", this.handleBodyClick);
      // this.editor.off('focus', this.onFocus);
      // this.editor.off('blur', this.onBlur);

      if (this.toolMenu && this.toolMenu.isShowBoard()) {
        this.toolMenu.closeBoard();
      }
      this.hideKeyboard();
      this.closeToolbar();
    },
    handleBodyClick(e) {
      // 点击编辑区域，显示toolbar，拉起软键盘。
      const contentElement = this.getContentElement();
      if (contentElement && contentElement.contains(e.target)) {
        if (this.toolMenu.isShowBoard()) {
          this.toolMenu.closeBoard();
        }
        if (this.editable) {
          this.showToolbar();
          this.showKeyboard();
        }
        return;
      }

      // 点击toolbar和tool board 不做处理。
      if (
        this.$refs.ttToolbarContainer.contains(e.target) ||
        this.toolMenu.board.contains(e.target)
      ) {
        return;
      }

      if (this.toolMenu.isShowBoard()) {
        this.toolMenu.closeBoard();
      }
    },
    handleScroll(/* e */) {
      const ttToolbarContainer = this.$refs.ttToolbarContainer;
      if (this.$refs.ttContainer.scrollTop > 1) {
        ttToolbarContainer.classList.add("tt-toolbar-shadow");
      } else {
        ttToolbarContainer.classList.remove("tt-toolbar-shadow");
      }
    },
    handleBodyMousedown() {
      // 存在问题：虽然之前选择的内容依然被高亮焦点（text selection），但是，之后点击tool编辑内容时，会弹出软键盘。
      // document.body.addEventListener("mousedown", e => {
      //  if (this.editor.isEditable) {
      //    // 点击tool/menu时，输入框不失去焦点，从而使得输入框中当前选择的内容会依然被高亮焦点（text selection）。
      //    if (this.$refs.ttToolbarContainer.contains(e.target)) {
      //      e.preventDefault();
      //    } else if (this.toolMenu && this.toolMenu.board.contains(e.target)) {
      //      e.preventDefault();
      //    }
      //  }
      // });
    },
    setContent(content) {
      this.editor.commands.setContent(content, false);
    },
    /**
     * EditorContent组件未渲染完成时，返回null/undefined。
     */
    getContentElement() {
      if (this.contentElement) return this.contentElement;
      this.contentElement =
        this.$refs.ttContent.$el.querySelector(".ProseMirror");
      return this.contentElement;
    },
    onKeyboardClick(/* e */) {
      if (this.toolMenu && this.toolMenu.isShowBoard()) {
        this.toolMenu.closeBoard();
      }
      this.showKeyboard();
    },
    onCompleteClick(/* e */) {
      if (this.toolMenu && this.toolMenu.isShowBoard()) {
        this.toolMenu.closeBoard();
      }
      this.hideKeyboard();

      if (this.editable && this.changed && this.options.onSave) {
        this.options.onSave(this);
      }

      this.closeToolbar();
    },
    setEditorHeight(height) {
      this.$refs.ttContainer.style.height = `${height}px`;
    },
    showToolbar() {
      this.$refs.ttToolbarContainer.classList.add("tt-show");
    },
    closeToolbar() {
      this.$refs.ttToolbarContainer.classList.remove("tt-show");
    },
    isShowToolbar() {
      return this.$refs.ttToolbarContainer.classList.contains("tt-show");
    },
    showKeyboard() {
      // 先移除焦点。
      // this.editor.commands.blur();
      this.editor.commands.focus();
    },
    hideKeyboard() {
      this.editor.commands.blur();
    },
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      delete this.editor;
    }
  },
};
</script>

<style scoped lang="scss">
.tt-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch; // 解决ios上滑动不流畅
}
.tt-container::-webkit-scrollbar {
  display: none;
  width: 0;
}

.tt-content {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB",
    "Microsoft YaHei UI", "Microsoft YaHei", SimSun, sans-serif;
  font-size: 11pt;
  position: relative;
}

.tt-toolbar-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  z-index: 99;
}
.tt-toolbar-container.tt-show {
  display: block;
}

.tt-toolbar {
  max-width: 500px;
  margin: 0 auto;
}

.tt-toolbar {
  .tt-complete {
    flex: 1;
    text-align: center;
    vertical-align: middle;
  }

  .tt-keyboard {
    flex: 1;
    text-align: center;
    vertical-align: middle;
    margin-right: 4px;
  }

  .tt-toolbar-tools-wrapper {
    // 有8个tool
    flex: 8;
    display: inline-block;
    overflow-x: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch; // 解决ios上滑动不流畅
  }
  .tt-toolbar-tools-wrapper::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .tt-toolbar-tools {
    width: 100%;
    white-space: nowrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: space-around;
    -ms-flex-align: center;
    align-items: center;
  }
}
.tt-toolbar-shadow {
  box-shadow: 0 3px 2px rgba(20, 20, 20, 0.12);
}

:deep(.ProseMirror:focus-visible) {
  outline: none;
}

:deep(.ProseMirror) {
  // max-width: 760px;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 24px 16px 280px 16px;
  box-sizing: border-box;
  border-top: none;
  //margin: 0 auto;
  border: none;
  box-shadow: none;
}

// 解决相连<p>元素之间紧挨图片之间存在空隙的问题。
:deep(.ProseMirror img.ProseMirror-separator) {
  // display: inline !important;
  // border: none !important;
  // margin: 0 !important;
  width: 0 !important;
  height: 0 !important;
}
</style>
