import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

/**
 * coa（Consultant Official Accounts）
 */
const routes = [
  {
    path: "/",
    // redirect: "/home",
  },
  {
    path: "/coa/bsdescmp",
    component: () => import("../views/coa/BsDescriptionMP.vue"),
  },
  {
    path: "/coa/bsimagerptmp",
    component: () => import("../views/coa/BsImageReportMP.vue"),
  },
  {
    path: "/coa/dsdocmp",
    component: () => import("../views/coa/DsDocMP.vue"),
  },
  {
    path: "/coa/imagerptmplmp",
    component: () => import("../views/coa/ImageReportTemplateMP.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
