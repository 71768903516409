import axios from "axios";
import { Base64 } from "js-base64";
import moment from "moment";
import AppConfig from "../config";

/*
//encrypt
var rawStr = "hello world!";
var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
var base64 = CryptoJS.enc.Base64.stringify(wordArray);
  
//decrypt
var parsedWordArray = CryptoJS.enc.Base64.parse(base64);
var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
*/

// 请求延时（毫秒数，如果请求话费超过了'timeout'的时间，请求将被中断）
//axios.defaults.timeout = 100000;

const _ocean_errors = {
  20001: "系统出错了",
  20002: "系统出错了",
  20003: "系统出错了",
  20004: "系统出错了",
  20005: "系统出错了",
  20006: "系统出错了",
  20007: "不允许该操作",
  20008: "系统出错了",

  80002: "用户不存在",
  80003: "用户已经存在",

  80004: "单品不存在",
  80005: "单品已经存在",
  80006: "单品正在使用",
  80007: "单品正在使用，不能删除",

  80008: "搭配不存在",
  80009: "搭配已经存在",
  80010: "搭配正在使用",

  80011: "标签组不存在",
  80012: "标签组已经存在",
  80013: "标签组正在使用",
  80014: "标签组正在使用，不能删除",
  80015: "标签组存在标签，不能删除",

  80016: "标签不存在",
  80017: "标签已经存在",
  80018: "标签正在使用",
  80019: "标签正在使用，不能删除",

  80020: "顾问号不存在",

  80021: "成员不存在",
  80022: "成员已经存在",

  80023: "成员申请不存在",
  80024: "成员申请已经存在",

  80025: "部门不存在",
  80026: "存在下级部门，不能删除",
  80027: "存在成员，不能删除",

  80028: "已经是管理员",
  80029: "不是管理员",
  80030: "添加管理员失败",

  80031: "服务不存在",
  80032: "服务已经存在",

  80033: "客户不存在",
  80034: "客户已经存在",

  80035: "标签组不存在",
  80036: "标签组已经存在",
  80037: "标签组正在使用",
  80038: "标签组正在使用，不能删除",
  80039: "标签组存在标签，不能删除",

  80040: "标签不存在",
  80041: "标签已经存在",
  80042: "标签正在使用",
  80043: "标签正在使用，不能删除",

  80044: "已经是会员",
  80045: "不是会员",

  80048: "订单不存在",
  80049: "订单已经存在",

  80050: "形象报告不存在",
  80051: "形象报告已经存在",
  80052: "形象报告删除失败",

  80053: "素材文件夹不存在",
  80054: "素材文件夹已经存在",

  80055: "图片素材不存在",
  80056: "图片素材已经存在",

  80057: "标签组不存在",
  80058: "标签组已经存在",
  80059: "标签组正在使用",
  80060: "标签组正在使用，不能删除",
  80061: "标签组存在标签，不能删除",

  80062: "标签不存在",
  80063: "标签已经存在",
  80064: "标签正在使用",
  80065: "标签正在使用，不能删除",

  80066: "单品不存在",
  80067: "单品已经存在",
  80068: "单品正在使用",
  80069: "单品正在使用，不能删除",

  80070: "搭配不存在",
  80071: "搭配已经存在",
  80072: "搭配正在使用",

  80073: "图片删除失败",

  80074: "照片删除失败",

  80075: "顾问不存在",
  80076: "顾问已经存在",

  80077: "照片不存在",
  80078: "照片已经存在",

  80079: "模板不存在",
  80080: "模板已经存在",
  80081: "模板删除失败",

  80082: "用户反馈不存在",
  80083: "用户反馈已经存在",

  80084: "惟若号已经存在",
  80085: "惟若号或密码错误",
  80086: "惟若密码错误",

  80087: "惟若密码已经设置",
  80088: "惟若密码未设置",

  80089: "搭配单品不存在",
  80090: "搭配单品已经存在",

  80091: "订单状态已经改变",
  80092: "不允许该操作",

  80093: "咨询不存在",
  80094: "删除失败",

  80095: "回复不存在",
  80096: "删除失败",

  80097: "不存在该评价",
  80098: "删除评价失败",

  80099: "成员已添加到顾问",

  80100: "客户设置了惟若账号，不能删除",

  80101: "服务产品已下架",
  80102: "服务产品已售罄",

  80103: "文件夹不存在或已被删除",
  80104: "文档不存在或已被删除",
  80105: "存在文件夹或文档，不允许删除",
  80106: "文档分享不存在或已被删除",
  80107: "文件夹或文档不存在，或已被删除",

  810002: "删除失败",

  820002: "注册失败！",
  820003: "登录失败！",
  820004: "系统出错了",
  820005: "系统出错了",
  820006: "系统出错了",
  820007: "系统出错了",
  820008: "微信绑定失败",
};

/** token填充字符，共 62 个 */
const _token_paddings =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const _axios = axios.create({
  baseURL: AppConfig.oceanApi,
  timeout: 30000,
});

_axios.interceptors.response.use(
  (res) => {
    if (res.status === 200 && typeof res.data === "object") {
      if (res.data.code === 0) {
        //return Promise.resolve(res);
        return res;
      } else {
        res.data.msg = _ocean_errors[res.data.code];
        if (!res.data.msg) {
          res.data.msg = "系统出错了";
        }
        return Promise.reject(res);
      }
    } else {
      res.data = { msg: "系统出错了!" };
      return Promise.reject(res);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

function getToken(uid) {
  let token = "";

  let uidBase64 = Base64.encode(`${uid}`);
  if (uidBase64) {
    /** 在uid base64每个字符前后插入3个随机得到的base64编码字符（前2个字符，后1个字符）； */
    for (let c of uidBase64) {
      let index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];

      index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];

      token += c;

      index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];
    }
  }

  return token;
}

/**
 *
 * @param {*} options
 * @param {*} ia 身份认证（identity authentication）参数
 *   {
 *     uid
 *     noAuth
 *   }
 * @returns
 */
function request(options = {}, ia) {
  if (!ia || (!ia.uid && !ia.noAuth)) {
    throw "Invalid identity";
  }

  let opts = Object.assign({}, options);

  // 时间戳
  if (!opts.timestamp) {
    opts.timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
  }

  // 身份认证参数
  if (ia.uid && !ia.noAuth) {
    opts.token = getToken(ia.uid);
  }

  return _axios.post("", opts);
}

/**
 * 获取用户信息
 * @param params: {
 *   id: 1,
 *   fields: [ '', '' ]
 * }
 */
function getUser(params, uid) {
  let opts = {
    method: "user.get",
    id: params.id,
    fields: params.fields,
  };

  return request(opts, { uid });
}

/**
 * 更新订单形象报告
 * @param imgrpt: {
 *  *id
 *   title
 *   url
 *   doctype
 *   ueditable: false, //用户可编辑
 *   report_updated: false, //报告内容更新
 * }
 */
function updateOrderImgRpt(imgrpt, uid) {
  let opts = {
    method: "team.bizorder.imgrpt.update",
  };
  Object.assign(opts, imgrpt);
  return request(opts, { uid });
}

/**
 * 更新文档
 * @param dsDoc: {
 *  *id
 *   oss_updated: false, //oss上文档内容更新
 * }
 */
function updateTeamDsDoc(dsDoc, uid) {
  let opts = {
    method: "team.dsdoc.update",
  };
  Object.assign(opts, dsDoc);
  return request(opts, { uid });
}

/**
 * 添加富文本图片列表
 * @param params: {
 *  *rid
 *  *images: [ '','' ], //图片列表，当前是OSS key，或者OSS object name
 * }
 */
function AddRtfImages(params, uid) {
  let opts = {
    method: "weiruo.rtf.imgs.add",
    rid: params.rid,
    images: params.images,
  };

  return request(opts, { uid });
}

/**
 * 更新形象报告模板
 * @param template: {
 *  *id
 *   name
 *   url
 *   doctype
 *   oss_updated: false, //oss上模板内容更新
 * }
 */
function updateTeamImgrptTemplate(template, uid) {
  let opts = {
    method: "team.imgrpt.template.update",
  };
  Object.assign(opts, template);
  return request(opts, { uid });
}

function getWxJsapiTicket(uid) {
  let opts = {
    method: "weixin.jsapi.ticket.get",
  };
  return request(opts, { uid });
}

/**
 *
 * @returns
 * {
 *   "credentials": {
 *     "accessKeyId": "STS.L4aBSCSJVMuKg5U1****",
 *     "accessKeySecret": "wyLTSmsyPGP1ohvvw8xYgB29dlGI8KMiH2pK****",
 *     "securityToken": "********",
 *     "expiration": "2015-04-09T11:52:19Z",
 *   }
 * }
 */
function getAliOssStsAssumerole(uid) {
  let opts = {
    method: "alioss.sts.assumerole",
  };
  return request(opts, { uid });
}

export default {
  getUser,
  updateOrderImgRpt,
  updateTeamDsDoc,
  AddRtfImages,
  updateTeamImgrptTemplate,
  getWxJsapiTicket,
  getAliOssStsAssumerole,
};
