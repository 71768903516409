import Icons from "../../assets/icons";

/**
 *
 * @param {Object} constrains { video: true, audio: true }
 * @returns Promise
 */
export function getUserMedia(constrains) {
  if (navigator.mediaDevices.getUserMedia) {
    // 最新标准API
    return navigator.mediaDevices.getUserMedia(constrains);
  }

  // webkit内核浏览器 | Firefox浏览器 | 旧版API
  var getUserMedia2 =
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.getUserMedia;
  if (getUserMedia2) {
    return new Promise(function (resolve, reject) {
      getUserMedia2.call(navigator, constrains, resolve, reject);
    });
  }

  return Promise.reject(new Error("无法打开相机"));
}

export function toggleAriaAttribute(element, attribute) {
  element.setAttribute(
    attribute,
    !(element.getAttribute(attribute) === "true")
  );
}

/**
 *
 * @param {*} url
 * @returns
 *   http://about:blank
 */
export function checkUrl(url) {
  if (!url) return "";
  if (url.indexOf("://") === -1) {
    return `http://${url}`;
  }
  return url;
}

export class Tool {
  constructor(menu) {
    this.menu = menu;

    /**
     *
     * element: HTMLElement
     * isActive: Function
     */
    this.actives = [];
  }

  /**
   * icon
   * class
   * onclick
   * isActive(editor)
   */
  buildButton(params) {
    const button = document.createElement("span");
    button.setAttribute("role", "button");
    // button.tabIndex = '0';
    if (params.class) {
      button.setAttribute("class", params.class);
    }
    button.classList.add("tt-tool");

    const icon = document.createElement("span");
    icon.classList.add("tt-icon");
    icon.innerHTML = params.icon;
    button.appendChild(icon);

    if (params.onclick) {
      const editor = this.menu.editor();
      button.addEventListener("click", (e) => {
        e.preventDefault();
        // e.stopPropagation();
        params.onclick({ editor });
      });
    }

    this.button = button;

    if (params.isActive) {
      this.actives.push({
        element: button,
        isActive: params.isActive,
      });
    }
  }

  /**
   *
   * @param {*} element
   * @param {*} option
   *   {
   *     handler: Function
   *     closeBoard: boolean
   *     notUpdateMenu: boolean
   *   }
   */
  addClickEventListener(element, option) {
    element.addEventListener("click", () => {
      if (option.handler) {
        const editor = this.menu.editor();
        option.handler({ editor, option, element });
      }
      if (option.closeBoard) {
        this.menu.closeBoard();
      }
      if (!option.notUpdateMenu) {
        this.menu.update();
      }
    });
  }

  update() {
    if (this.actives.length > 0) {
      const editor = this.menu.editor();
      this.actives.forEach((item) => {
        item.element.classList.toggle("is-active", item.isActive(editor));
      });
    }
  }
}

export class PanelTool extends Tool {
  buildPanel(attributes) {
    this.panel = document.createElement("div");

    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        this.panel.setAttribute(key, attributes[key]);
      });
    }

    this.panel.classList.add("tt-panel");
  }

  buildRow(attributes) {
    const row = document.createElement("div");
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        row.setAttribute(key, attributes[key]);
      });
    }
    return row;
  }

  /**
   * icon
   * text
   * class
   * iconClass
   * textClass
   * onclick
   * notUpdateMenu
   * isActive(editor)
   */
  buildRowItem(params) {
    const button = document.createElement("span");
    button.setAttribute("role", "button");
    if (params.class) {
      button.setAttribute("class", params.class);
    }

    if (params.icon) {
      const icon = document.createElement("span");
      if (params.iconClass) {
        icon.setAttribute("class", params.iconClass);
      } else {
        icon.classList.add("tt-panel-icon");
      }
      icon.innerHTML = params.icon;
      button.appendChild(icon);
    }

    if (params.text) {
      if (params.textClass) {
        const text = document.createElement("span");
        text.setAttribute("class", params.textClass);
        button.appendChild(text);
      } else {
        button.innerHTML += params.text;
      }
    }

    if (params.onclick) {
      const editor = this.menu.editor();
      button.addEventListener("click", (e) => {
        e.preventDefault();
        // e.stopPropagation();
        params.onclick({ editor });
        if (!params.notUpdateMenu) {
          this.menu.update();
        }
      });
    }

    if (params.isActive) {
      this.actives.push({
        element: button,
        isActive: params.isActive,
      });
    }

    return button;
  }

  buildItemSplit(attributes) {
    const split = document.createElement("span");
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        split.setAttribute(key, attributes[key]);
      });
    }
    if (!attributes || !attributes.class) {
      split.classList.add("tt-panel-split");
    }
    return split;
  }

  buildBlankItem(attributes) {
    const blank = document.createElement("span");
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        blank.setAttribute(key, attributes[key]);
      });
    }
    if (!attributes || !attributes.class) {
      blank.classList.add("tt-blank-item");
    }
    return blank;
  }

  togglePanel() {
    const showing = this.isShowPanel();
    this.button.classList.toggle("tt-expanded", !showing);
    this.panel.classList.toggle("tt-show", !showing);
  }

  showPanel() {
    this.button.classList.add("tt-expanded");
    this.panel.classList.add("tt-show");
  }

  closePanel() {
    this.button.classList.remove("tt-expanded");
    this.panel.classList.remove("tt-show");
  }

  isShowPanel() {
    return this.panel.classList.contains("tt-show");
  }
}

export const FONT_SIZES = [
  "9pt",
  "10pt",
  "11pt",
  "12pt",
  "13pt",
  "14pt",
  "15pt",
  "16pt",
  "18pt",
  "20pt",
  "22pt",
  "24pt",
  "30pt",
  "36pt",
];
export const defaultFontSize = "11pt";

export class FontTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.fontFamily,
      class: "tt-font tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-font-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    this.buildBoldRow();
    this.buildHeading1Row();
    this.buildHeading2Row();
    this.buildFontSizeRow();
  }

  buildBoldRow() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const boldItem = this.buildRowItem({
      icon: Icons.bold,
      class: "tt-bold tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().toggleBold().run();
      },
      isActive: (editor) => {
        return editor.isActive("bold");
      },
    });
    row.appendChild(boldItem);

    const italicItem = this.buildRowItem({
      icon: Icons.italic,
      class: "tt-italic tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleItalic().run();
      },
      isActive: (editor) => {
        return editor.isActive("italic");
      },
    });
    row.appendChild(italicItem);

    const underlineItem = this.buildRowItem({
      icon: Icons.underline,
      class: "tt-underline tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleUnderline().run();
      },
      isActive: (editor) => {
        return editor.isActive("underline");
      },
    });
    row.appendChild(underlineItem);

    const strikeItem = this.buildRowItem({
      icon: Icons.strike,
      class: "tt-strike tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleStrike().run();
      },
      isActive: (editor) => {
        return editor.isActive("strike");
      },
    });
    row.appendChild(strikeItem);
  }

  buildHeading1Row() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const h1Item = this.buildRowItem({
      text: "标题1",
      class: "tt-heading1 tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 1 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 1 });
      },
    });
    row.appendChild(h1Item);

    const h2Item = this.buildRowItem({
      text: "标题2",
      class: "tt-heading2 tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 2 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 2 });
      },
    });
    row.appendChild(h2Item);

    const h3Item = this.buildRowItem({
      text: "标题3",
      class: "tt-heading3 tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 3 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 3 });
      },
    });
    row.appendChild(h3Item);
  }

  buildHeading2Row() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const boldItem = this.buildRowItem({
      text: "标题4",
      class: "tt-heading4 tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 4 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 4 });
      },
    });
    row.appendChild(boldItem);

    const italicItem = this.buildRowItem({
      text: "标题5",
      class: "tt-heading5 tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 5 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 5 });
      },
    });
    row.appendChild(italicItem);

    const underlineItem = this.buildRowItem({
      text: "标题6",
      class: "tt-heading6 tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleHeading({ level: 6 }).run();
      },
      isActive: (editor) => {
        return editor.isActive("heading", { level: 6 });
      },
    });
    row.appendChild(underlineItem);

    const strikeItem = this.buildRowItem({
      text: "正文",
      class: "tt-heading-p tt-panel-item",
      onclick: () => {
        const editor = this.menu.editor();
        const { level } = editor.getAttributes("heading");
        if (level || level === 0) {
          editor.chain().toggleHeading({ level }).run();
        }
      },
      isActive: (editor) => {
        const { level } = editor.getAttributes("heading");
        return !(level || level === 0);
      },
    });
    row.appendChild(strikeItem);
  }

  buildFontSizeRow() {
    const row = this.buildRow({
      class: "tt-panel-1itemrow",
    });
    this.panel.appendChild(row);

    const fontSizeValue = document.createElement("span");
    fontSizeValue.classList.add("tt-font-size-value");
    fontSizeValue.innerHTML = parseInt(defaultFontSize);

    const boldItem = this.buildRowItem({
      icon: Icons.fontSize,
      text: "字号",
      class: "tt-panel-plain-item",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      isActive: (editor) => {
        const { fontSize } = editor.getAttributes("textStyle");
        fontSizeValue.innerHTML = fontSize
          ? parseInt(fontSize)
          : parseInt(defaultFontSize);
        return false;
      },
    });
    row.appendChild(boldItem);

    const split1 = document.createElement("span");
    split1.classList.add("flex-1");
    row.appendChild(split1);

    const decItem = this.buildRowItem({
      icon: Icons.horizontalLine,
      class: "tt-decrement tt-panel-plain-item",
      iconClass: "tt-panel-icon tt-font-size-dec",
      onclick: () => {
        const editor = this.menu.editor();
        const { fontSize } = editor.getAttributes("textStyle");
        let index = FONT_SIZES.indexOf(fontSize);
        // let canDecrement = false;
        if (index === -1) {
          index = FONT_SIZES.indexOf(defaultFontSize);
          if (index > 0) {
            // canDecrement = index > 1;
            let newSize1 = FONT_SIZES[index - 1];
            editor.chain().setFontSize(newSize1).run();
            fontSizeValue.innerHTML = parseInt(newSize1);
          } else {
            let minSize = FONT_SIZES[0];
            editor.chain().setFontSize(minSize).run();
            fontSizeValue.innerHTML = parseInt(minSize);
          }
        } else if (index > 0) {
          // canDecrement = index > 1;
          let newSize = FONT_SIZES[index - 1];
          editor.chain().setFontSize(newSize).run();
          fontSizeValue.innerHTML = parseInt(newSize);
        }
      },
    });
    row.appendChild(decItem);

    row.appendChild(fontSizeValue);

    const incItem = this.buildRowItem({
      icon: Icons.add,
      class: "tt-increment tt-panel-plain-item",
      iconClass: "tt-panel-icon tt-font-size-inc",
      onclick: () => {
        const editor = this.menu.editor();
        const { fontSize } = editor.getAttributes("textStyle");
        let index = FONT_SIZES.indexOf(fontSize);
        // let canIncrement = false;
        if (index === -1) {
          index = FONT_SIZES.indexOf(defaultFontSize);
          if (index === -1) {
            // canIncrement = true;
            let minSize = FONT_SIZES[0];
            editor.chain().setFontSize(minSize).run();
            fontSizeValue.innerHTML = parseInt(minSize);
          } else if (index < FONT_SIZES.length - 1) {
            // canIncrement = index < (FONT_SIZES.length - 2);
            let newSize2 = FONT_SIZES[index + 1];
            editor.chain().setFontSize(newSize2).run();
            fontSizeValue.innerHTML = parseInt(newSize2);
          } else {
            // 默认值是最大值。
            // canIncrement = index < (FONT_SIZES.length - 2);
            fontSizeValue.innerHTML = parseInt(defaultFontSize);
          }
        } else if (index < FONT_SIZES.length - 1) {
          // canIncrement = index < (FONT_SIZES.length - 2);
          let newSize = FONT_SIZES[index + 1];
          editor.chain().setFontSize(newSize).run();
          fontSizeValue.innerHTML = parseInt(newSize);
        }
      },
    });
    row.appendChild(incItem);
  }
}

export const FONT_FAMILYS = [
  {
    text: "默认字体",
    class: "tt-panel-vitem tt-panel-firstvitem",
    onclick: ({ editor }) => {
      editor.chain().unsetFontFamily().run();
    },
    isActive: (editor) => {
      const { fontFamily } = editor.getAttributes("textStyle");
      return !fontFamily;
    },
  },
  {
    text: "宋体",
    class: "tt-panel-vitem tt-fontfamily-SimSun",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("SimSun").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "SimSun" });
    },
  },
  {
    text: "黑体",
    class: "tt-panel-vitem tt-fontfamily-SimHei",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("SimHei").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "SimHei" });
    },
  },
  {
    text: "微软雅黑",
    class: "tt-panel-vitem tt-fontfamily-Microsoft-YaHei",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Microsoft YaHei").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Microsoft YaHei" });
    },
  },
  {
    text: "楷体",
    class: "tt-panel-vitem tt-fontfamily-KaiTi",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("KaiTi").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "KaiTi" });
    },
  },
  {
    text: "仿宋",
    class: "tt-panel-vitem tt-fontfamily-FangSong",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("FangSong").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "FangSong" });
    },
  },
  {
    text: "Arial",
    class: "tt-panel-vitem tt-fontfamily-Arial",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Arial, Helvetica, sans-serif").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Arial, Helvetica, sans-serif",
      });
    },
  },
  {
    text: "Times New Roman",
    class: "tt-panel-vitem tt-fontfamily-Times-New-Roman",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Times New Roman, Times, serif").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Times New Roman, Times, serif",
      });
    },
  },
  {
    text: "Courier New",
    class: "tt-panel-vitem tt-fontfamily-Courier-New",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Courier New, Courier, monospace").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Courier New, Courier, monospace",
      });
    },
  },
  {
    text: "Georgia",
    class: "tt-panel-vitem tt-fontfamily-Georgia",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Georgia, serif").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Georgia, serif" });
    },
  },
  {
    text: "Source Code Pro",
    class: "tt-panel-vitem tt-fontfamily-Source-Code-Pro",
    onclick: ({ editor }) => {
      editor.chain().setFontFamily("Source Code Pro").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Source Code Pro" });
    },
  },
];

export class FontFamilyTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.font,
      class: "tt-font-family tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-font-family-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    FONT_FAMILYS.forEach((family) => {
      const row = this.buildRow({
        class: "tt-plain-row",
      });
      this.panel.appendChild(row);

      const item = this.buildRowItem(family);
      row.appendChild(item);
    });
  }
}

export const COLORS = [
  [
    { color: "#ffffff", tip: "纯白", showBorder: true },
    { color: "#000000", tip: "纯黑" },
    { color: "#ff0000", tip: "红" },
    { color: "#ff7800", tip: "橙" },
    { color: "#ffd900", tip: "黄" },
    { color: "#a3e043", tip: "葱绿" },
    { color: "#37d9f0", tip: "湖蓝" },
    { color: "#4da8ee", tip: "天色" },
    { color: "#956fe7", tip: "藤紫" },
  ],
  [
    { color: "#f3f3f4", tip: "白练" },
    { color: "#cccccc", tip: "白鼠" },
    { color: "#fef2f0", tip: "樱" },
    { color: "#fef5e7", tip: "缟" },
    { color: "#fefcd9", tip: "练" },
    { color: "#edf6e8", tip: "芽" },
    { color: "#e6fafa", tip: "水" },
    { color: "#ebf4fc", tip: "缥" },
    { color: "#f0edf6", tip: "丁香" },
  ],
  [
    { color: "#d7d8d9", tip: "灰青" },
    { color: "#a5a5a5", tip: "鼠" },
    { color: "#fbd4d0", tip: "虹" },
    { color: "#ffd7b9", tip: "落柿" },
    { color: "#f9eda6", tip: "花叶" },
    { color: "#d4e9d6", tip: "白绿" },
    { color: "#c7e6ea", tip: "天青" },
    { color: "#cce0f1", tip: "天空" },
    { color: "#dad5e9", tip: "水晶" },
  ],
  [
    { color: "#7b7f83", tip: "薄钝" },
    { color: "#494949", tip: "墨" },
    { color: "#ee7976", tip: "甚三红" },
    { color: "#faa573", tip: "珊瑚" },
    { color: "#e6b322", tip: "金" },
    { color: "#98c091", tip: "薄青" },
    { color: "#79c6cd", tip: "白群" },
    { color: "#6eaad7", tip: "薄花" },
    { color: "#9c8ec1", tip: "紫苑" },
  ],
  [
    { color: "#41464b", tip: "石墨" },
    { color: "#333333", tip: "黑" },
    { color: "#be1a1d", tip: "绯红" },
    { color: "#b95514", tip: "棕黄" },
    { color: "#ad720e", tip: "土黄" },
    { color: "#1c7231", tip: "苍翠" },
    { color: "#1c7892", tip: "孔雀" },
    { color: "#19439c", tip: "琉璃" },
    { color: "#511b78", tip: "青莲" },
  ],
];

export class ColorTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.fontColor,
      class: "tt-color tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-color-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    this.buildRemoveRow();

    const grid = document.createElement("div");
    grid.classList.add("tt-color-panel-grid");

    COLORS.forEach((rowColors) => {
      const row = this.buildColorRow(rowColors);
      grid.appendChild(row);
    });

    this.panel.appendChild(grid);
  }

  buildRemoveRow() {
    const row = document.createElement("div");

    const remove = document.createElement("span");
    remove.setAttribute("role", "button");
    remove.setAttribute("class", "tt-button tt-color-panel-remove-color");

    const icon = document.createElement("span");
    icon.classList.add("tt-color-panel-remove-icon");
    icon.innerHTML = Icons.eraser;
    remove.appendChild(icon);

    const text = document.createElement("span");
    text.innerHTML = "移除颜色";
    remove.appendChild(text);

    const option = {
      handler: ({ editor }) => {
        editor.chain().unsetColor().run();
      },
    };
    this.addClickEventListener(remove, option);

    row.appendChild(remove);
    this.panel.appendChild(row);
  }

  buildColorRow(colors) {
    const row = this.buildRow({
      class: "tt-color-panel-grid-row",
    });

    colors.forEach((color) => {
      const item = this.buildColorItem(color);
      row.appendChild(item);
    });

    return row;
  }

  buildColorItem(option) {
    const item = document.createElement("span");
    item.setAttribute("role", "button");
    item.setAttribute("class", "tt-button tt-color-panel-grid-item");
    item.style.backgroundColor = option.color;

    // if (option.tip) {
    //   item.setAttribute('title', option.tip);
    // }

    if (option.showBorder) {
      item.classList.add("tt-color-panel-grid-item-bordered");
    }

    option.handler = ({ editor }) => {
      editor.chain().setColor(option.color).run();
    };
    this.addClickEventListener(item, option);

    this.actives.push({
      element: item,
      isActive: (editor) => {
        return editor.isActive("textStyle", { color: option.color });
      },
    });
    return item;
  }
}

export const BACKGROUD_COLORS = [
  [
    { color: "#ffffff", tip: "纯白", showBorder: true },
    { color: "#000000", tip: "纯黑" },
    { color: "#ff0000", tip: "红" },
    { color: "#ff7800", tip: "橙" },
    { color: "#ffd900", tip: "黄" },
    { color: "#a3e043", tip: "葱绿" },
    { color: "#37d9f0", tip: "湖蓝" },
    { color: "#4da8ee", tip: "天色" },
    { color: "#956fe7", tip: "藤紫" },
  ],
  [
    { color: "#f3f3f4", tip: "白练" },
    { color: "#cccccc", tip: "白鼠" },
    { color: "#fef2f0", tip: "樱" },
    { color: "#fef5e7", tip: "缟" },
    { color: "#fefcd9", tip: "练" },
    { color: "#edf6e8", tip: "芽" },
    { color: "#e6fafa", tip: "水" },
    { color: "#ebf4fc", tip: "缥" },
    { color: "#f0edf6", tip: "丁香" },
  ],
  [
    { color: "#d7d8d9", tip: "灰青" },
    { color: "#a5a5a5", tip: "鼠" },
    { color: "#fbd4d0", tip: "虹" },
    { color: "#ffd7b9", tip: "落柿" },
    { color: "#f9eda6", tip: "花叶" },
    { color: "#d4e9d6", tip: "白绿" },
    { color: "#c7e6ea", tip: "天青" },
    { color: "#cce0f1", tip: "天空" },
    { color: "#dad5e9", tip: "水晶" },
  ],
  [
    { color: "#7b7f83", tip: "薄钝" },
    { color: "#494949", tip: "墨" },
    { color: "#ee7976", tip: "甚三红" },
    { color: "#faa573", tip: "珊瑚" },
    { color: "#e6b322", tip: "金" },
    { color: "#98c091", tip: "薄青" },
    { color: "#79c6cd", tip: "白群" },
    { color: "#6eaad7", tip: "薄花" },
    { color: "#9c8ec1", tip: "紫苑" },
  ],
  [
    { color: "#41464b", tip: "石墨" },
    { color: "#333333", tip: "黑" },
    { color: "#be1a1d", tip: "绯红" },
    { color: "#b95514", tip: "棕黄" },
    { color: "#ad720e", tip: "土黄" },
    { color: "#1c7231", tip: "苍翠" },
    { color: "#1c7892", tip: "孔雀" },
    { color: "#19439c", tip: "琉璃" },
    { color: "#511b78", tip: "青莲" },
  ],
];

export class BackgroundColorTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.fontBackground,
      class: "tt-bgcolor tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-bgcolor-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    this.buildRemoveRow();

    const grid = document.createElement("div");
    grid.classList.add("tt-color-panel-grid");

    BACKGROUD_COLORS.forEach((rowColors) => {
      const row = this.buildColorRow(rowColors);
      grid.appendChild(row);
    });

    this.panel.appendChild(grid);
  }

  buildRemoveRow() {
    const row = document.createElement("div");

    const remove = document.createElement("span");
    remove.setAttribute("role", "button");
    remove.setAttribute("class", "tt-button tt-color-panel-remove-color");

    const icon = document.createElement("span");
    icon.classList.add("tt-color-panel-remove-icon");
    icon.innerHTML = Icons.eraser;
    remove.appendChild(icon);

    const text = document.createElement("span");
    text.innerHTML = "移除背景色";
    remove.appendChild(text);

    const option = {
      handler: ({ editor }) => {
        editor.chain().unsetBackgroundColor().run();
      },
    };
    this.addClickEventListener(remove, option);

    row.appendChild(remove);
    this.panel.appendChild(row);
  }

  buildColorRow(colors) {
    const row = this.buildRow({
      class: "tt-color-panel-grid-row",
    });

    colors.forEach((color) => {
      const item = this.buildColorItem(color);
      row.appendChild(item);
    });

    return row;
  }

  buildColorItem(option) {
    const item = document.createElement("span");
    item.setAttribute("role", "button");
    item.setAttribute("class", "tt-button tt-color-panel-grid-item");
    item.style.backgroundColor = option.color;

    // if (option.tip) {
    //   item.setAttribute('title', option.tip);
    // }

    if (option.showBorder) {
      item.classList.add("tt-color-panel-grid-item-bordered");
    }

    option.handler = ({ editor }) => {
      editor.chain().setBackgroundColor(option.color).run();
    };
    this.addClickEventListener(item, option);

    this.actives.push({
      element: item,
      isActive: (editor) => {
        return editor.isActive("textStyle", { backgroundColor: option.color });
      },
    });
    return item;
  }
}

export class TypeSettingTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.list.bullet,
      class: "tt-typesetting tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-typesetting-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    this.buildAlignRow();
    this.buildListRow();
    this.buildScriptRow();
  }

  buildAlignRow() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const leftItem = this.buildRowItem({
      icon: Icons.align.left,
      class: "tt-align-left tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().unsetTextAlign().run();
      },
      isActive: (editor) => {
        return editor.isActive({ textAlign: "left" });
      },
    });
    row.appendChild(leftItem);

    const centerItem = this.buildRowItem({
      icon: Icons.align.center,
      class: "tt-align-center tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().setTextAlign("center").run();
      },
      isActive: (editor) => {
        return editor.isActive({ textAlign: "center" });
      },
    });
    row.appendChild(centerItem);

    const rightItem = this.buildRowItem({
      icon: Icons.align.right,
      class: "tt-align-right tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().setTextAlign("right").run();
      },
      isActive: (editor) => {
        return editor.isActive({ textAlign: "right" });
      },
    });
    row.appendChild(rightItem);

    const justifyItem = this.buildRowItem({
      icon: Icons.align.justify,
      class: "tt-align-justify tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().setTextAlign("justify").run();
      },
      isActive: (editor) => {
        return editor.isActive({ textAlign: "justify" });
      },
    });
    row.appendChild(justifyItem);
  }

  buildListRow() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const orderedListItem = this.buildRowItem({
      icon: Icons.list.numbered,
      class: "tt-list-ordered tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().toggleOrderedList().run();
      },
      isActive: (editor) => {
        return editor.isActive("orderedList");
      },
    });
    row.appendChild(orderedListItem);

    const bulletListItem = this.buildRowItem({
      icon: Icons.list.bullet,
      class: "tt-list-bullet tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleBulletList().run();
      },
      isActive: (editor) => {
        return editor.isActive("bulletList");
      },
    });
    row.appendChild(bulletListItem);

    const taskListItem = this.buildRowItem({
      icon: Icons.list.check,
      class: "tt-list-task tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleTaskList().run();
      },
      isActive: (editor) => {
        return editor.isActive("taskList");
      },
    });
    row.appendChild(taskListItem);

    const split1 = this.buildItemSplit();
    row.appendChild(split1);

    const indentItem = this.buildRowItem({
      icon: Icons.indent,
      class: "tt-indent tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().indent().run();
      },
    });
    row.appendChild(indentItem);

    const outdentItem = this.buildRowItem({
      icon: Icons.outdent,
      class: "tt-outdent tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().outdent().run();
      },
    });
    row.appendChild(outdentItem);
  }

  buildScriptRow() {
    const row = this.buildRow({
      class: "tt-panel-row",
    });
    this.panel.appendChild(row);

    const superscriptItem = this.buildRowItem({
      icon: Icons.script.super,
      class: "tt-superscript tt-panel-item tt-panel-firstitem",
      onclick: () => {
        this.menu.editor().chain().toggleSuperscript().run();
      },
      isActive: (editor) => {
        return editor.isActive("superscript");
      },
    });
    row.appendChild(superscriptItem);

    const subscriptItem = this.buildRowItem({
      icon: Icons.script.sub,
      class: "tt-subscript tt-panel-item",
      onclick: () => {
        this.menu.editor().chain().toggleSubscript().run();
      },
      isActive: (editor) => {
        return editor.isActive("subscript");
      },
    });
    row.appendChild(subscriptItem);

    const blank1 = this.buildBlankItem();
    row.appendChild(blank1);

    const split1 = this.buildItemSplit();
    row.appendChild(split1);

    const blank2 = this.buildBlankItem({
      class: "tt-blank-item tt-panel-firstitem",
    });
    row.appendChild(blank2);

    const blank3 = this.buildBlankItem();
    row.appendChild(blank3);
  }
}

export class AddTool extends PanelTool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.add,
      class: "tt-add tt-tool",
      onclick: () => {
        this.menu.showBoard(this);
      },
    });

    this.buildPanel({
      class: "tt-add-panel tt-panel",
    });
    this.buildContent();
  }

  buildContent() {
    this.buildPhotoAlbumRow();

    const splitRow = this.buildRow({
      class: "tt-split-row",
    });
    this.panel.appendChild(splitRow);

    this.buildHorizontalLineRow();
    this.buildBlockquoteRow();
  }

  uploadImage(input, editor) {
    const uploadFiles = [];

    // 一次最多50个图片
    const maxCount = Math.min(input.files.length, 50);
    for (var i = 0; i < maxCount; i++) {
      const file = input.files.item(i);
      uploadFiles.push(file);
    }
    if (uploadFiles.length > 0) {
      const menuOptions = this.menu.options;
      if (menuOptions && menuOptions.image && menuOptions.image.upload) {
        menuOptions.image.upload(uploadFiles, editor);
      } else {
        const promises = uploadFiles.map((file) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(file);
          });
        });
        Promise.all(promises).then((images) => {
          images.forEach((img) => {
            editor.commands.setImage({ src: img });
          });
        });
      }
    }
  }

  buildPhotoAlbumRow() {
    const row = this.buildRow({
      class: "tt-plain-row",
    });
    this.panel.appendChild(row);

    // const that = this;
    const photoAlbumItem = this.buildRowItem({
      icon: Icons.image,
      // text: '从相册选择图片',
      text: "插入图片",
      class: "tt-panel-vitem tt-panel-firstvitem",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      onclick: ({ editor }) => {
        this.menu.closeBoard();

        let fileInput = this.panel.querySelector(
          "input.tt-upload-image[type=file]"
        );
        if (fileInput == null) {
          fileInput = document.createElement("input");
          fileInput.style.display = "none";
          fileInput.setAttribute("type", "file");
          fileInput.setAttribute("accept", "image/*");
          fileInput.classList.add("tt-upload-image");
          // fileInput.setAttribute('multiple', 'multiple');
          // fileInput.setAttribute('capture', 'camera');
          fileInput.addEventListener("change", () => {
            this.uploadImage(fileInput, editor);
            fileInput.value = "";
          });

          this.panel.appendChild(fileInput);
        }
        fileInput.click();
      },
    });
    row.appendChild(photoAlbumItem);
  }

  buildCameraRow() {
    const row = this.buildRow({
      class: "tt-plain-row",
    });
    this.panel.appendChild(row);

    const cameraItem = this.buildRowItem({
      icon: Icons.camera,
      text: "拍摄",
      class: "tt-panel-vitem",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      onclick: () => {
        this.menu.closeBoard();
      },
    });
    row.appendChild(cameraItem);
  }

  buildResourceRow() {
    const row = this.buildRow({
      class: "tt-plain-row",
    });
    this.panel.appendChild(row);

    const cameraItem = this.buildRowItem({
      icon: Icons.browseFilesAdd,
      text: "从资源空间选择图片",
      class: "tt-panel-vitem",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      onclick: () => {
        this.menu.closeBoard();
      },
    });
    row.appendChild(cameraItem);
  }

  buildHorizontalLineRow() {
    const row = this.buildRow({
      class: "tt-plain-row",
    });
    this.panel.appendChild(row);

    const horizontalLineItem = this.buildRowItem({
      icon: Icons.horizontalLine,
      text: "分割线",
      class: "tt-panel-vitem tt-panel-firstvitem",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      onclick: () => {
        this.menu.editor().chain().setHorizontalRule().run();
        this.menu.closeBoard();
      },
    });
    row.appendChild(horizontalLineItem);
  }

  buildBlockquoteRow() {
    const row = this.buildRow({
      class: "tt-plain-row",
    });
    this.panel.appendChild(row);

    const blockquoteItem = this.buildRowItem({
      icon: Icons.blockquote,
      text: "引用",
      class: "tt-panel-vitem",
      iconClass: "tt-panel-icon tt-panel-icon-margin-has-text",
      onclick: () => {
        this.menu.editor().chain().toggleBlockquote().run();
        this.menu.closeBoard();
      },
      isActive: (editor) => {
        return editor.isActive("blockquote");
      },
    });
    row.appendChild(blockquoteItem);
  }
}

export class RedoTool extends Tool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.redo,
      class: "tt-redo tt-tool",
      onclick: () => {
        const editor = this.menu.editor();
        editor.chain().redo().run();
        this.menu.update();
      },
    });
  }
}

export class UndoTool extends Tool {
  constructor(menu) {
    super(menu);

    this.buildButton({
      icon: Icons.undo,
      class: "tt-undo tt-tool",
      onclick: () => {
        const editor = this.menu.editor();
        editor.chain().undo().run();
        this.menu.update();
      },
    });
  }
}

export const panelMinWidth = 240;
export const panelMaxWidth = 300;

export class ToolMenu {
  /**
   *
   * @param {*} ttEditor
   * @param {*} options
   *   {
   *     toolbar
   *     items
   *     image: {
   *       upload(): Function
   *       options: []
   *     }
   *   }
   */
  constructor(ttEditor, options) {
    this.ttEditor = ttEditor;
    this.options = options;
    this.tools = [];
    this.panelTools = [];

    this.board = document.createElement("div");
    this.board.classList.add("tt-board");

    this.panelWrapper = document.createElement("div");
    this.panelWrapper.classList.add("tt-panel-wrapper");
    this.board.appendChild(this.panelWrapper);

    if (typeof options.toolbar === "string") {
      this.toolbar = document.querySelector(options.toolbar);
      this.addTools(this.toolbar, options.items);
    } else if (options.toolbar instanceof HTMLElement) {
      this.toolbar = options.toolbar;
      this.addTools(this.toolbar, options.items);
    } else {
      this.toolbar = document.createElement("span");
      this.toolbar.classList.add("tt-toolbar");
      this.addTools(this.toolbar, options.items);
      ttEditor.$refs.ttContainer.insertBefore(
        this.toolbar,
        ttEditor.$refs.ttContent.$el
      );
    }
    // 设置min-width，确保出现滚动条时，能完全显示toolbar。
    // 否则，宽度溢出时，"justify-content: space-around;"会导致toolbar左边不能完全显示。
    this.toolbar.style.minWidth = `${24 * this.tools.length}px`;

    this.boardBottom = document.createElement("div");
    this.boardBottom.classList.add("tt-board-bottom");
    this.board.appendChild(this.boardBottom);

    ttEditor.$refs.ttContainer.appendChild(this.board);

    if (document.documentElement.clientWidth < panelMinWidth) {
      this.panelWrapper.style.width = `${panelMinWidth}px`;
    } else if (document.documentElement.clientWidth > panelMaxWidth) {
      this.panelWrapper.style.width = `${panelMaxWidth}px`;
    }

    // console.log(
    //   document.documentElement.clientWidth,
    //   panelMinWidth,
    //   panelMaxWidth,
    //   this.panelWrapper.style.width
    // );

    // const clickListener = e => {
    //  if (this.isShowBoard() && !this.toolbar.contains(e.target) && !this.board.contains(e.target)) {
    //    this.closeBoard();
    //  }
    // };
    // document.body.addEventListener('click', clickListener);
  }

  addTools(toolbar /* , items */) {
    this.addTool(toolbar, new FontTool(this));
    this.addTool(toolbar, new FontFamilyTool(this));
    this.addTool(toolbar, new ColorTool(this));
    this.addTool(toolbar, new BackgroundColorTool(this));
    this.addTool(toolbar, new TypeSettingTool(this));
    this.addTool(toolbar, new AddTool(this));
    this.addTool(toolbar, new UndoTool(this));
    this.addTool(toolbar, new RedoTool(this));
  }

  addTool(toolbar, tool) {
    this.tools.push(tool);
    if (tool instanceof PanelTool) {
      this.panelTools.push(tool);
      this.panelWrapper.appendChild(tool.panel);
    }
    toolbar.appendChild(tool.button);
  }

  editor() {
    return this.ttEditor.editor;
  }

  showBoard(tool) {
    this.board.classList.add("tt-show");

    if (this.panelTools.length > 0) {
      const currTool = tool ? tool : this.panelTools[0];
      this.panelTools.forEach((tool1) => {
        if (tool1 === currTool) {
          tool1.showPanel();
        } else {
          tool1.closePanel();
        }
      });
    }
  }

  closeBoard() {
    this.board.classList.remove("tt-show");
    this.closePanels();
  }

  isShowBoard() {
    return this.board.classList.contains("tt-show");
  }

  closePanels() {
    this.panelTools.forEach((tool) => {
      tool.closePanel();
    });
  }

  setBoardHeight(height) {
    this.board.style.height = `${height}px`;
  }

  update() {
    this.tools.forEach((tool) => {
      tool.update();
    });
  }
}
