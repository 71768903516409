/**
 *
 */

/**
 * 去前后空格
 */
function trim(str) {
  if (!str) return "";
  if (str.trim) return str.trim();
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 提取数字
 */
function pickNumber(value) {
  let num = String(value).replace(/[^0-9]/g, "");
  return num;
}

/**
 * 提取带小数点的数字和正负
 */
function pickDigit(value) {
  let digit = String(value).replace(/[^0-9.-]/g, "");
  return digit;
}

/**
 * 数字补0（填充截取法）
 */
function fillZero_arr(num, len) {
  //这里用slice和substr均可
  return (Array(len).join("0") + num).slice(-len);
}

/**
 * 数字补0（转为小数）
 */
/*
function fillZero_dec(num, len) {
  var decimal = num / Math.pow(10, len);
  //toFixed指定保留几位小数
  decimal = decimal.toFixed(len) + "";
  return decimal.substr(decimal.indexOf(".") + 1);
}
*/

function hex(num, x, y) {
  if (!y) {
    return parseInt(num.toString()).toString(x);
  } else {
    return parseInt(num.toString(), y).toString(x);
  }
}

export default {
  trim,
  pickNumber,
  pickDigit,
  hex,
  fillZero: fillZero_arr,
};
