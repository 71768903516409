<!--
 * URL查询参数:
 * k: uid
 * i: id
 * n: name
 * t: [op], op: 0/undefined - add, 1 - edit, 2 - readonly;
 * title: 标题
-->

<template>
  <div ref="irContainer" class="ir-container">
    <tiptap-editor
      ref="irEditor"
      :editable="editable"
      :options="editorOptions"
      :content="content"
    >
      <div slot="header" ref="irTopbarContainer" class="ir-topbar-container">
        <div class="ir-topbar">
          <div class="ir-topbar-center">
            <template v-if="showEditable">
              <van-icon
                :name="editable ? 'edit' : 'lock'"
                size="18px"
                color="#d9d9d9"
              />
              <span class="ir-topbar-editable-txt">{{
                editable ? "编辑" : "只读"
              }}</span>
            </template>
          </div>
        </div>
      </div>
    </tiptap-editor>
    <van-dialog
      v-model="isChangedBack"
      title="温馨提示"
      message="要放弃此次修改吗？"
      show-cancel-button
      @confirm="comfirmChangedBack"
      @cancel="cancelChangedBack"
    >
    </van-dialog>
  </div>
</template>

<script>
import TiptapEditor from "../../components/tiptap/TiptapEditor.vue";
import AliOSS from "../../api/alioss";
import Ocean from "../../api/ocean";
import wx from "weixin-js-sdk";
import AppConfig from "../../config";

export default {
  components: {
    TiptapEditor,
  },
  data() {
    return {
      editor: null,
      editable: false,
      showEditable: false,
      editorOptions: {
        onSave: this.onSave,
        toolMenu: {
          image: {
            upload: (imageFiles, editor) => {
              let images = [];

              for (let file of imageFiles) {
                if (file.size > AppConfig.imageReport.maxImageSize) {
                  this.$toast.fail(AppConfig.imageReport.maxImageSizeError);
                  return;
                }

                let imgOssKey = AliOSS.getImageKey(
                  AliOSS.getImageName(file.name),
                  this.id
                );
                images.push(imgOssKey);
              }

              Ocean.AddRtfImages(
                {
                  rid: this.id,
                  images,
                },
                this.uid
              )
                .then(async () => {
                  const client = await AliOSS.getClient();
                  for (let idx = 0; idx < images.length; idx++) {
                    // 第三个参数可以自定义headers
                    await client.put(images[idx], imageFiles[idx]);

                    let imgUrl = AliOSS.getCdnUrl(images[idx]);
                    editor.commands.setImage({ src: imgUrl });
                  }
                })
                .catch(() => {
                  this.$toast.fail("部分图片上传失败");
                });
            },
          },
        },
      },
      uid: 0,
      id: 0,
      name: "",
      op: 0, // 0 - add, 1 - edit, 2 - readonly;
      content: "",
      // 提示：放弃修改
      isChangedBack: false,
    };
  },
  async created() {
    if (!this.readUrlQuery()) return;

    if (!this.uid) {
      this.$toast.fail("无法读取报告");
      return;
    }

    if (this.op > 0) {
      await this.downloadReport();
    } else {
      this.initEditor();
    }
  },
  mounted() {
    history.pushState({ page: 1 }, null, document.URL);

    // 点击小程序返回时，会触发popstate事件
    window.addEventListener("popstate", this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  methods: {
    readUrlQuery() {
      let k = this.$route.query.k ? this.$route.query.k.trim() : "";
      let i = this.$route.query.i ? this.$route.query.i.trim() : "";
      let n = this.$route.query.n ? this.$route.query.n.trim() : "";
      let t = this.$route.query.t ? this.$route.query.t.trim() : "";

      if (this.$route.query.title) {
        document.title = this.$route.query.title;
      }

      if (!k || !i || !n) return false;

      this.uid = parseInt(k);
      this.id = parseInt(i);
      this.name = n;

      if (t.length > 0) {
        let op = parseInt(t);
        this.op = op ? op : 0;
      }

      return true;
    },
    async downloadReport() {
      let client = null;
      try {
        client = await AliOSS.getClient(this.uid);
        const result = await client.get(
          AliOSS.getImageReportKey(this.name, this.id)
        );
        this.content = result.content.toString();
        this.initEditor();
      } catch (err) {
        // 404（目标Object不存在），参考https://help.aliyun.com/document_detail/31980.htm?spm=a2c4g.11186623.0.0.b7ff7dbbVRQuEJ#reference-ccf-rgd-5db
        if (err && err.status === 404) {
          this.initEditor();
        } else {
          this.$toast.fail("无法读取报告");
        }
      }
    },
    initEditor() {
      this.editable = this.op !== 2;
      this.$nextTick(() => {
        this.$refs.irEditor.initialize();
        this.showEditable = true;
      });
    },
    async onSave() {
      if (
        !this.editable ||
        !this.$refs.irEditor.changed ||
        !this.id ||
        !this.name
      )
        return;

      const editor = this.$refs.irEditor.editor;

      try {
        const client = await AliOSS.getClient(this.uid);

        const data = new AliOSS.OSS.Buffer(editor.getHTML());
        await client.put(AliOSS.getImageReportKey(this.name, this.id), data);

        this.$refs.irEditor.changed = false;

        Ocean.updateOrderImgRpt(
          {
            id: this.id,
            report_updated: true,
          },
          this.uid
        )
          .then((/* res */) => {})
          .catch((/* res */) => {});

        this.$toast.success("保存完成");
      } catch (e) {
        this.$toast.fail("保存失败，请重试");
      }
    },
    handlePopstate() {
      if (this.$refs.irEditor.changed) {
        history.pushState({ page: 1 }, null, document.URL);
        this.isChangedBack = true;
      } else {
        wx.miniProgram.navigateBack({
          delta: 1,
        });
      }
    },
    comfirmChangedBack() {
      this.isChangedBack = false;
      wx.miniProgram.navigateBack({
        delta: 1,
      });
    },
    cancelChangedBack() {
      this.isChangedBack = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ir-container {
  height: 100vh;
  box-sizing: border-box;
  background-color: #f9f9f9;

  // This element is a flex container for easier rendering.
  // display: flex;
  // flex-flow: column nowrap;
}

.ir-topbar-container {
  width: 100%;
  height: 40px;
  background-color: #fafafa;
  box-sizing: border-box;

  border-bottom: 1px solid #f3f3f3;

  display: -ms-flexbox;
  display: flex;
  // -ms-flex-pack: center;
  // justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;

  // Create the illusion of the toolbar floating over the editable.
  // box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  // transition: height .2s ease-in;
}

.ir-topbar {
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
  white-space: nowrap;
}

.ir-topbar-center {
  display: inline-block;
  margin: 0 auto;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ir-topbar-editable-txt {
  display: inline;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
  font-size: 10px;
}

:deep(.tt-content) {
  // 减去<ir-topbar-container>的高度
  height: calc(100vh - 40px);
  box-sizing: border-box;
}
</style>
