/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 1.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Code also contributed by Greg Holt
 * See http://pajhome.org.uk/site/legal.html for details.
 */

function getFileName(filepath) {
  let filename = "";

  if (filepath) {
    let pathIndex = Math.max(
      filepath.lastIndexOf("/"),
      filepath.lastIndexOf("\\")
    );

    if (pathIndex < 0) {
      filename = filepath;
    } else {
      filename = filepath.substring(pathIndex + 1);
    }
  }

  return filename;
}

function getFileExtension(file) {
  let ext = "";
  let filename = getFileName(file);

  if (filename) {
    let extIndex = filename.lastIndexOf(".");
    if (extIndex >= 0) {
      ext = filename.substring(extIndex + 1);
    }
  }

  return ext;
}

export default {
  getFileName,
  getFileExtension,
};
