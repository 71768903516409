/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 1.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Code also contributed by Greg Holt
 * See http://pajhome.org.uk/site/legal.html for details.
 */

import STR from "./string";

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

function guid() {
  const date = new Date();
  var msstr =
    date.getFullYear() +
    STR.fillZero(date.getMonth() + 1, 2) +
    STR.fillZero(date.getDate(), 2) +
    STR.fillZero(date.getHours(), 2) +
    STR.fillZero(date.getMinutes(), 2) +
    STR.fillZero(date.getSeconds(), 2) +
    STR.fillZero(date.getMilliseconds(), 3);
  return s4() + s4() + STR.hex(msstr, 16) + s4() + s4();
}

export default {
  guid,
};
